import { useEffect } from "react";
import {Helmet} from "react-helmet";

function ThankYouForBooking() {
    
    return (<>
        
        <div className="container">
        <Helmet>
            <script>{`{"gtag('event', 'conversion', {'send_to': 'AW-16611742657/elJOCLXY1rsZEMGnjPE9'});"}`}</script>
        </Helmet>
            <h2 className="display-5">Tour Booked!</h2>
            <p>
                Thank you so much for booking your tour! Please come a few minutes early to ensure we can start on time. Don't forget sunblock!
            </p>
            <p>
                Consider wearing an undershirt and bringing some clothing you can change on the fly.
            </p>
            <p>
                We will do one trip on the U-Bahn, so please ensure you have a ticket (for instance a day ticket or 49 Euro ticket) or are prepared to purchase one.
            </p>
            <p>
                See you there! Questions? Email contact@ffphototours.com
            </p>
        </div>
    </>)
}

export default ThankYouForBooking