

import { Fragment } from 'react/jsx-runtime';
import Navbar from './Navbar';
import Home from './Home';
import Features from './Features';
import Pricing from './Pricing';
import RefundsAndCancellations from './RefundsAndCancellations';
import Impressum from './Impressum';
import ThankYouForBooking from './ThankYouForBooking';
import { Router, Routes, Route } from 'react-router-dom'



function App() {
  return (
    <>


          <Navbar />
          <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/features" element={<Features/>}/>
            <Route path="/pricing" element={<Pricing/>}/>
            <Route path="/refundAndCancellations" element={<RefundsAndCancellations/>}/>
            <Route path="/impressum" element={<Impressum/>}/>
            <Route path="/thankyouForBooking" element={<ThankYouForBooking/>}/>
          </Routes>

    </>

  );
}

export default App;
