function Features() {
    return (<>
        <div className="container">
            <h1 className='text-dark display-5 m-2'>Features</h1>
            <div>
                <p>The tour goes quick, in just under two hours. The small groups of no more than six
                    models (that's you!) will walk through the city, getting their photos taken at various places in frankfurt.
                </p>
                <p>Locations include:</p>
                <ul>
                    <li>The "broken subway car" at Bockenheimer Warte</li>
                    <li>The Euro sign at Willy Brant Platz</li>
                    <li>Alt Oper (The Old Opera)</li>
                    <li>Eschenheimer Turm</li>
                    <li>The Bull and Bear</li>
                    <li>Romer</li>
                    <li>Isener Steg</li>
                </ul>
                <p>Your photographer will take photos of you, encouraging you to try different poses, and capture your best side!</p>
                <p>After the tour, within 48 hours (usually quicker!) you'll get 5-10 of the best photos from the tour. All touched up and ready to share (or keep to yourself!)</p>
                <p>The tour will require participants to walk approximately 5 kilometers (3 miles). Please ensure you are physically 
                    capable to walk this distance and have the appropriate attire for the day!</p>
                <p>All finished digital photos belong to the customer at time of purchase. With your permission, I may ask to post to the site or instagram.</p>
            </div>
            <h4 className='text-dark'>Personal Data</h4>
            <p>Personal data will only be kept for payment and contact information.</p>
        </div>
    </>)
}

export default Features;