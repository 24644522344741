

function Impressum(){
    return(<>
        <div className="container">
            <h1 className='text-dark display-5'>Impressum</h1>
            <p>Owner: Rob Bailey</p>
            <p>Address: Hainer Weg 80, Frankfurt am Main, 60599</p>
            <p>Contact Email: contact@ffphototours.com</p>
            <p>Contact Phone: +49 (0) 1747 391530</p>
        </div>
    </>)
}

export default Impressum;