
function RefundsAndCancellations() {
    return (<>
        <div className="container">
            <h1 className='text-dark display-5'>Refunds and Cancellations</h1>
            <div>Refunds will be issued if booking is cancelled at least 24 hours before scheduled.</div>
            <br />
            <div>This is a purely outdoor service. Meaning that tours may be cancelled due to inclement weather. If it's raining heavily or
                other extreme weather, the tour will be cancelled and your money will be refunded.</div>
            
        </div>
    </>)
}

export default RefundsAndCancellations;