import './Home.css'
import { Navbar } from 'react-bootstrap';

const images = [
  {
    src: "gallery_images/P1150747-2-734x1024.jpg",
    active: 'active'
  },
  {
    src: "gallery_images/P1140994-855x1024.jpg"
  },

  {
    src: "gallery_images/P1150720-730x1024.jpg"
  },
  {
    src: "gallery_images/P1140051-725x1024.jpg"
  },
  {
    src: "gallery_images/P1150150-1024x769.jpg"
  },
]

function Home() {
  return (<>
    <div className="App container">

      <div>
        <div className='row'>
          <div className='col'>
            <h1 className='text-dark display-3'>Frankfurt Photo Tours</h1>
            <div className='text-secondary'>Get your photos out of the way so you can enjoy your vacation!</div>

            <br />

            <div className='text-secondary'>Frankfurt Photo Tours is a unique experience where vistors are guided through the streets of Frankfurt am Main to have
              their pictures taken at some of the most picturesque sites in the city.</div>


            <br />
            <div className='text-secondary'>
              Visitors then receive digitally mastered versions of the photographs via email within 48 hours.</div>
            {/* <br /> */}
            <div className='d-flex justify-content-center' >
              <a href="https://cal.com/ffphototours/vacation-photos">
                <img src="/gallery_images/PhotoTours_ad_3999.png" width="512" />
              </a>
            </div>
            <div className='border rounded-4 tip-bubble p-4'> Are you tired of having your phone out all the time on your trip? We've got your back!</div>
            <br />

            <div className='d-flex justify-content-center'>
              <img src="gallery_images/photo_bridge_cartoon.png" width={'250vh'} />
            </div>
            <br />
            <div className='d-flex justify-content-center text-secondary'>Questions? Email contact@ffphototours.com</div>

          </div>
          <div className='col'>

            <div id="gallery_carousel" className="carousel slide" data-bs-ride="carousel">
              <div className='carousel-inner'>
                {images.map((image, index) => {
                  return (
                    <div key={index} className={'carousel-item ' + image.active}>
                      <img src={image.src} className={'d-block card-img-top '} />
                    </div>
                  )
                })}
              </div>
              <br />
              <a href='https://cal.com/ffphototours/vacation-photos' className='d-flex justify-content-center fs-2 btn border-secondary m-2'>Click here to schedule now!</a>
              <div className='text-light border rounded-4 tip-bubble-2 p-4'> Great for Instagram or Facebook! (or any social media site!)</div>
              <br />
              <div className='d-flex justify-content-center text-secondary'>Save 18% by ordering here instead of Viator!</div>
              <br />
              <div className='d-flex justify-content-center text-secondary'>
                <span className='link-primary'><a href="https://www.instagram.com/ffphototours/">@ffphototours </a></span>{` `}
              </div>
              <div className='d-flex justify-content-center text-secondary'> on Instagram!!</div>
              <div className='d-flex justify-content-center' >
                <a href="https://www.instagram.com/ffphototours/"><img src="/gallery_images/ffphototours_qr.png" width="120" /></a>
              </div>

            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  </>)
}

export default Home;