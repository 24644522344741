

function Pricing() {
    return (<>
        <div className="container">
            <h1 className='text-dark display-5'>Pricing</h1>
            <h4>Standard Tour: 49.99 Euro per person</h4>
            <div>This includes the tour and a collection of 5-10 touched up photographs, ready to show off to your friends.</div>
            <br/>
            {/* <h4 className='text-dark'>Add-Ons</h4>
            <h5>Instagram Package - Additional 4.99 Euro</h5>
            <p>Get an extra copy of your photos cropped for in instagram's 4x5 ratio. For upload directly into Insta!</p>
            <br/>
            <h5>"Give it to me Raw!" Package - Additional 9.99 Euro</h5>
            <p>I will post a raw copy of all photos taken on the cloud for 30 days for you to download and touch up yourself!</p>
            <br /> */}
            <h4 className='text-dark'>Large Groups</h4>
            <div>Have a group larger than 6 people and want to schedule a group shoot? Email contact@ffphototours to schedule custom time/pricing.</div>

            <br />
            <h4 className='text-dark'>Payments</h4>
            <p>All payments will occur on secure platforms including stripe.com or paypal business.</p>
            <p>Personal data will only be kept for payment and contact. All finished digital photos belong to the customer at time of purchase.</p>
        </div>
    </>)
}

export default Pricing;